import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const AboutUs = () => {
  return (
    <Wrapper id="about">
      <Container>
        <ImageContainer>
          <StaticImage src="../images/semiconductor.jpg" alt="" />
        </ImageContainer>

        <Content>
          <h2>
            About <span>Us</span>
          </h2>
          <p>
            Established In the year 2017 at Faridabad, Haryana, we{' '}
            <strong>Ronak Electrical & Electronics</strong> are as Sole
            Proprietorship based firm, engaged as the foremost Authorized
            Manufacturing And Trader Dealer of Stabilizer Control Card,Single
            Phase Stabilizers, Single Phase Servo Stabilizer and many more.
          </p>
        </Content>
      </Container>
    </Wrapper>
  )
}

export default AboutUs

const Wrapper = styled.section`
  margin-top: -20px;
`

const Container = styled.div`
  max-width: 1200px;
  padding: 0 10px 50px;
  margin: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;

  h2 {
    font-size: 44px;
    max-width: 250px;
    border-bottom: 2px solid ${({ theme }) => theme.color.p};
    span {
      color: ${({ theme }) => theme.color.p};
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    h2 {
      font-size: 36px;
      max-width: 200px;
    }
  }
`

const ImageContainer = styled.div``

const Content = styled.div`
  max-width: 500px;
  p {
    font-size: 18px;
    line-height: 28px;
  }
`
