import React from 'react'
import styled from 'styled-components'
import { EnquiryForm } from './EnquiryForm'

const CallUs = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <Left>
            <h2>
              Call Us <br />
              We are <span>helping you</span>
            </h2>
            <div>
              <p>
                Get Details of Servo Stablizer Cards, APFC Relays, Counters,
                Timers, Stepper Motor, Stepper Motor Drives, Motion Controllers,
                Ceiling Fan Winding Machine, Winding machine Control &amp; their
                Spare Parts.
              </p>
              <p>
                The Products provided by us are manufactured using premium
                quality raw materials and are acclaimed for their ease of
                installation and lower needs for you.
              </p>
            </div>
          </Left>

          <EnquiryForm />
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default CallUs

const Wrapper = styled.section``

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 50px 10px;
  h2 {
    font-size: 38px;
    margin-bottom: 100px;
    max-width: 380px;
    border-bottom: 2px solid ${({ theme }) => theme.color.p};
    span {
      color: ${({ theme }) => theme.color.p};
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    h2 {
      font-size: 36px;
      max-width: 350px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    form {
      margin: auto;
    }
  }
`

const Left = styled.div`
  @media (max-width: 1000px) {
    h2 {
      margin: 0;
    }
  }
`
