import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Link } from 'gatsby'

const HomepageBanner = ({ products = [] }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <Wrapper>
      <Container>
        <Slider {...settings}>
          {products.map((p, i) => (
            <Item key={i} to={`/product/${p.name}`}>
              <GatsbyImage
                alt={p.name}
                image={p.featureImage.gatsbyImageData}
              />
              <Name>{p.name}</Name>
            </Item>
          ))}
        </Slider>
      </Container>
    </Wrapper>
  )
}

export default HomepageBanner

const Wrapper = styled.section`
  margin-bottom: 40px;
  max-height: 60vh;
  * {
    max-height: 60vh;
  }
`

const Container = styled.div``

const Item = styled(Link)`
  position: relative;
`
const Name = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0, rgb(0 0 0 / 30%), transparent);
  margin: 0;
  min-height: 70px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 26px;
  font-weight: bold;
  padding: 0 10px;
`
