import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HomepageBanner from '../components/HomepageBanner'
import WhyChooseUs from '../components/WhyChooseUs'
import CallUs from '../components/CallUs'
import AboutUs from '../components/AboutUs'
import Seo from '../components/seo'

const Index = (props) => {
  let products = props.data.products.nodes
  products.sort((a, b) => b.order - a.order)

  return (
    <Layout location={props.location}>
      <Seo title="Ronak Electronics - Best Electronics seller in Faridabad" />
      <HomepageBanner products={products} />
      <AboutUs />
      <WhyChooseUs />
      <CallUs />
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  {
    products: allContentfulProduct {
      nodes {
        id
        name
        category
        description
        order
        body {
          raw
        }
        featureImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
          resize {
            src
          }
        }
      }
    }
  }
`

// blogs: allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
//   nodes {
//     title
//     slug
//     publishDate(formatString: "MMMM Do, YYYY")
//     tags
//     heroImage {
//       gatsbyImageData(
//         layout: FULL_WIDTH
//         placeholder: BLURRED
//         width: 424
//         height: 212
//       )
//     }
//     description {
//       childMarkdownRemark {
//         html
//       }
//     }
//   }
// }
