import React, { useState } from 'react'
import styled from 'styled-components'

export const EnquiryForm = ({ hideHeading = true }) => {
  const [data, setData] = useState({
    fullName: '',
    mobile: '',
    msg: '',
    email: '',
  })

  const onChange = ({ target, value }) => {
    setData({
      [target.name]: value,
    })
  }

  return (
    <Wrapper>
      <Container>
        {!hideHeading && <h3 level={2}>Enquiry Form</h3>}
        <Form
          action="https://formsubmit.co/ronakelectrical98@gmail.com"
          method="POST"
        >
          <input type="hidden" name="_cc" value="vatsvatsharsh2000@gmail.com" />
          <input
            type="text"
            placeholder="Your name *"
            required
            data-aos="zoom-in"
            value={data.fullName}
            name="fullName"
            onChange={onChange}
          />
          <input
            type="tel"
            placeholder="Your Mobile No. *"
            required
            data-aos="zoom-in"
            value={data.mobile}
            name="mobile"
            onChange={onChange}
          />
          <input
            type="email"
            placeholder="Your Email id*"
            required
            data-aos="zoom-in"
            value={data.email}
            name="email"
            onChange={onChange}
          />

          <textarea
            type="text"
            placeholder="Type your message..."
            rows={4}
            data-aos="zoom-in"
            name="msg"
            onChange={onChange}
          />
          <input type="submit" value="Submit" />
        </Form>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.section``

const Container = styled.div``

const Form = styled.form`
  display: grid;
  grid-gap: 30px;
  background: radial-gradient(
      54.74% 54.74% at 50% 45.26%,
      rgba(255, 203, 33, 0.41) 0%,
      rgba(255, 203, 33, 0.5294) 41.49%,
      #ffcb21 100%
    ),
    #ffffff;
  border-radius: 25px;
  padding: 40px 20px;
  max-width: 500px;
  margin-left: auto;
  input,
  textarea {
    border: none;
    background: #ffffff;
    border-radius: 10px;
    padding: 15px 15px;
    transition: 0.3s !important;
    font-size: 16px;
  }
  input:focus,
  textarea:focus {
    outline: none;
    transform: scale(1.03);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  p {
    margin: 0;
  }
  input[type='submit'] {
    text-transform: uppercase;
    letter-spacing: 1px;
    background: black;
    width: 150px;
    color: white;
    margin: auto;
    cursor: pointer;
  }
`
