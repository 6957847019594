import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const WhyChooseUs = () => {
  return (
    <Wrapper id="why-us">
      <Container>
        <h2>
          Why <span>Choose us?</span>
        </h2>

        <Flex>
          <Grid>
            <div>
              <h3>High Quality</h3>
              <p>
                The quality we provide is unparalleled and we never deliver
                solutions or services before testing them properly. We have a
                rigorous quality check process to make sure whatever we deliver
                is up to our client&apos;s expectations.
              </p>
            </div>
            <div>
              <h3>Intelligent Control</h3>
              <p>
                Our aim is to satisfy our customer need for their complex
                processes that cannot be controlled by traditional methods. So ,
                we are using Intelligent microchip Controllers in our hardware
                to solve their complex problem.
              </p>
            </div>
            <div>
              <h3>Low Current Operation</h3>
              <p>
                1% of the main load and take corrective action. PF control
                option requires minimum and 6% of mains current for others.
              </p>
            </div>
            <div>
              <h3>24x7 Customer Support</h3>
              <p>
                We are highly confident in our promise to serve you better. Our
                focus is to build long term relationships with our customers,
                not simply a quick sale!
              </p>
            </div>
          </Grid>

          <div>
            <StaticImage
              src="../images/whyus.jpg"
              alt="why choose raunak electronics"
            />
          </div>
        </Flex>
      </Container>
    </Wrapper>
  )
}

export default WhyChooseUs

const Wrapper = styled.section`
  background: rgb(196 196 196 / 15%);
`

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 50px 10px;
  h2 {
    font-size: 44px;
    margin-top: 0;
    max-width: 380px;
    border-bottom: 2px solid ${({ theme }) => theme.color.p};
    span {
      color: ${({ theme }) => theme.color.p};
    }
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 36px;
      max-width: 300px;
    }
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    flex: 1;
    &:first-child {
      flex: 2;
    }
  }

  @media (max-width: 1000px) {
    > div:nth-of-type(2) {
      display: none;
    }
  }
`

const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  padding-right: 40px;
  align-items: stretch;
  > div {
    background: white;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 4px 10px 0 #d0d0d0;
    &:hover {
      background: radial-gradient(
          54.74% 54.74% at 50% 45.26%,
          rgba(255, 203, 33, 0.41) 0%,
          rgba(255, 203, 33, 0.5294) 41.49%,
          #ffcb21 100%
        ),
        #ffffff;
    }
  }
  p {
    margin: 0;
    color: rgb(0 0 0 / 80%);
  }
  h3 {
    font-size: 22px;
    margin-top: 0;
  }
  @media (max-width: 1000px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`
